import cx from "classnames"
import PropTypes from "prop-types"
import { Text } from "components/Text"

function RouteCircle({ variant, className, closed }) {
  return (
    <div
      className={cx(
        "rounded-full w-4 h-4 flex shrink-0 grow-0",
        {
          "bg-secondary-default": ["start", "single"].includes(variant),
          "border-2 border-primary-default bg-white":
            variant == "intermediary" && !closed,
          "border-2 border-grey-medium bg-white":
            variant == "intermediary" && closed,
          "bg-primary-dark": variant == "stop",
        },
        className
      )}
    />
  )
}

RouteCircle.propTypes = {
  variant: PropTypes.oneOf(["start", "stop", "intermediary", "single"])
    .isRequired,
  className: PropTypes.string,
  closed: PropTypes.bool,
}

export function Route(props) {
  const { steps, className, ...rest } = props

  const classNames = cx("flex", "flex-col", "items-start", className)
  const firstElement = steps[0]
  const lastElement = steps[steps.length - 1]

  return (
    <div className={classNames} {...rest}>
      <TravelStep
        variant={steps.length > 1 ? "start" : "single"}
        place={firstElement.place ? firstElement.place : null}
        date={firstElement.date ? firstElement.date : null}
        nbElements={steps.length}
      />
      {steps.slice(1, -1).map((step, index) => {
        return (
          <div className="flex flex-col items-start" key={index}>
            <TravelStep
              variant="intermediary"
              place={step.place}
              date={step.date ? step.date : null}
              nbElements={steps.length}
            />
          </div>
        )
      })}
      {steps.length > 1 ? (
        <div className="flex flex-col items-start">
          <TravelStep
            variant="stop"
            place={steps.length > 1 ? lastElement.place : firstElement.place}
            date={
              steps.length > 1 && lastElement.date
                ? lastElement.date
                : firstElement.date
                ? firstElement.date
                : null
            }
          />
        </div>
      ) : null}
    </div>
  )
}

Route.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      place: PropTypes.string,
      date: PropTypes.instanceOf(Date),
    })
  ),
}

function TravelStep(props) {
  const { variant, place, date } = props

  return (
    <TimelineItem variant={variant}>
      <div className="flex flex-col items-start justify-center">
        <Text variant="body1">{place}</Text>
        {date ? (
          <Text variant="caption" className="text-light">
            {date.toLocaleDateString()}
          </Text>
        ) : null}
      </div>
    </TimelineItem>
  )
}

TravelStep.propTypes = {
  variant: PropTypes.oneOf(["start", "stop", "intermediary", "single"])
    .isRequired,
  place: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date),
  className: PropTypes.string,
}

function TimelineItem({ variant, children, closed }) {
  const shouldShowTopLine = ["intermediary", "stop"].includes(variant)
  const shouldShowBottomLine = ["intermediary", "start"].includes(variant)

  return (
    <div
      className={cx(
        { "justify-center": variant !== "intermediary" },
        "space-x-2 flex items-stretch"
      )}
    >
      <div className="flex items-center relative">
        {shouldShowTopLine ? <TimelineLine position="top" /> : null}
        {shouldShowBottomLine ? <TimelineLine position="bottom" /> : null}
        <RouteCircle className="relative" variant={variant} closed={closed} />
      </div>
      <div className="py-1">{children}</div>
    </div>
  )
}

TimelineItem.propTypes = {
  variant: PropTypes.oneOf(["start", "stop", "intermediary", "single"])
    .isRequired,
  children: PropTypes.node,
  closed: PropTypes.bool,
}

function TimelineLine({ position }) {
  return (
    <div
      className={cx(
        "w-1 bg-primary-default absolute left-1/2 h-1/2 transform -translate-x-1/2",
        { "top-0": position === "top", "bottom-0": position === "bottom" }
      )}
    />
  )
}

TimelineLine.propTypes = {
  position: PropTypes.oneOf(["top", "bottom"]).isRequired,
}
