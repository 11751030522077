import { useTranslator } from "components/Translator"
import { Button } from "components/Button"
import { Modal, ModalContent } from "components/Modal"
import { FacebookSquare } from "components/Icon/FacebookSquare"
import { Envelope } from "components/Icon/Envelope"

export const ShareModal = ({ isOpen, onRequestClose, title, url, email }) => {
  const translator = useTranslator()
  return (
    <Modal isOpen={isOpen} title={title} onRequestClose={onRequestClose}>
      <ModalContent>
        <div className="flex flex-col space-y-4">
          <Button
            type="button"
            fullWidth
            variant="outline"
            iconLeft={<Envelope className="w-4" />}
            href={`mailto:?subject=${email.subject}&body=${email.body}`}
            color="secondary"
            target="_blank"
          >
            {translator.trans("ShareModal.byMail.title", null, "components")}
          </Button>
          <Button
            type="button"
            fullWidth
            variant="outline"
            iconLeft={<FacebookSquare className="w-4" />}
            href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
            color="secondary"
            target="_blank"
          >
            {translator.trans("ShareModal.byFacebook", null, "components")}
          </Button>
        </div>
      </ModalContent>
    </Modal>
  )
}

ShareModal.defaultProps = {
  url: new URL(window.location.toString()).toString(),
}
