import { CalendarDay, ClockRegular } from "components/Icon"
import { DynamicIcon } from "components/Icon/DynamicIcon"
import { Tag } from "components/Tag"
import { InlineList } from "components/InlineList"
import { useTranslator } from "components/Translator"
import * as AdvertType from "enums/AdvertType"
import * as BoatSaleType from "enums/BoatSaleType"
import * as BoatCoOwnershipType from "enums/BoatCoOwnershipType"
import * as AdvertAvailability from "enums/AdvertAvailability"

export const AdvertTags = ({ advert }) => {
  const translator = useTranslator()

  const dates =
    advert.departureDate && advert.arrivalDate
      ? translator.trans(
          "AdvertTags.dateInterval",
          {
            from: new Date(advert.departureDate).toLocaleDateString(
              translator.locale
            ),
            to: new Date(advert.arrivalDate).toLocaleDateString(
              translator.locale
            ),
          },
          "components"
        )
      : advert.departureDate
      ? new Date(advert.departureDate).toLocaleDateString()
      : null

  const isRental = advert.type.enum_value === AdvertType.RENTAL
  const isSale = advert.type.enum_value === AdvertType.SALE

  const isCoOwnership =
    advert.saleType?.enum_value === BoatSaleType.CO_OWNERSHIP

  const coOwnershipTypeToTranslationKey = {
    [BoatCoOwnershipType.BECOME]: "AdvertTags.type.share.becomeOwner",
    [BoatCoOwnershipType.LOOK_FOR]: "AdvertTags.type.share.searchOwner",
    [BoatCoOwnershipType.SELL_SHARES]: "AdvertTags.type.share.sell",
  }

  const availabilityLabel = getAvailabilityTagLabel(advert)

  return (
    <InlineList separator={null}>
      {advert.navigationTags?.map((navigationTag) => {
        return (
          <InlineList.Item key={navigationTag.name}>
            <Tag
              color="default"
              label={navigationTag.name.split("(").shift().trim()}
              iconStart={
                navigationTag.icon ? (
                  <DynamicIcon name={navigationTag.icon} className="w-3.5" />
                ) : null
              }
            />
          </InlineList.Item>
        )
      })}
      {advert.navigationThemes?.map((navigationTheme) => {
        return (
          <InlineList.Item key={navigationTheme.enum_label}>
            <Tag
              color="default"
              label={navigationTheme.enum_label.split("(").shift().trim()}
              iconStart={
                navigationTheme.icon ? (
                  <DynamicIcon name={navigationTheme.icon} className="w-3.5" />
                ) : null
              }
            />
          </InlineList.Item>
        )
      })}

      {availabilityLabel ? (
        <InlineList.Item>
          <Tag
            color="default"
            label={translator.trans(
              getAvailabilityTagLabel(advert),
              null,
              "components"
            )}
            iconStart={<ClockRegular className="w-4" />}
          />
        </InlineList.Item>
      ) : null}

      {dates ? (
        <InlineList.Item>
          <Tag
            color="default"
            label={dates}
            iconStart={<CalendarDay className="w-3" />}
          />
        </InlineList.Item>
      ) : null}
      {advert.availabilityPeriods?.map((availabilityPeriod) => {
        return (
          <InlineList.Item key={availabilityPeriod.enum_value}>
            <Tag color="default" label={availabilityPeriod.enum_label} />
          </InlineList.Item>
        )
      })}
      {isSale ? (
        <InlineList.Item>
          <Tag
            color="default"
            label={translator.trans(
              isCoOwnership
                ? "AdvertTags.type.coOwnership"
                : "AdvertTags.type.sale",
              null,
              "components"
            )}
          />
        </InlineList.Item>
      ) : null}
      {advert.coOwnershipType &&
      coOwnershipTypeToTranslationKey[advert.coOwnershipType.enum_value] ? (
        <InlineList.Item>
          <Tag
            color="default"
            label={translator.trans(
              coOwnershipTypeToTranslationKey[
                advert.coOwnershipType.enum_value
              ],
              null,
              "components"
            )}
          />
        </InlineList.Item>
      ) : null}
      {isRental && advert.rentalDuration ? (
        <InlineList.Item>
          <Tag
            color="default"
            label={translator.trans(
              "AdvertTags.type.rental.rentalDuration",
              { rentalDuration: advert.rentalDuration },
              "components"
            )}
          />
        </InlineList.Item>
      ) : null}
      {isRental && advert.rentalDurationLong ? (
        <InlineList.Item>
          <Tag
            color="default"
            label={translator.trans(
              "AdvertTags.type.rental.rentalDurationLong",
              null,
              "components"
            )}
          />
        </InlineList.Item>
      ) : null}
      {isRental && advert.rentalPeriods?.length > 0 ? (
        <InlineList.Item>
          <Tag
            color="default"
            label={translator.trans(
              "AdvertTags.type.rental.rentalType",
              null,
              "components"
            )}
          />
        </InlineList.Item>
      ) : null}
    </InlineList>
  )
}

const getAvailabilityTagLabel = (advert) => {
  if (!advert.availability) {
    return null
  }

  if (advert.availability?.enum_value === AdvertAvailability.PERIOD) {
    switch (advert.type.enum_value) {
      case AdvertType.BOAT:
      case AdvertType.FLEET:
        return "AdvertTags.availability.period.boat"

      case AdvertType.CREW:
        return "AdvertTags.availability.period.crew"

      default:
        return null
    }
  } else {
    switch (advert.type.enum_value) {
      case AdvertType.BOAT:
      case AdvertType.FLEET:
        return "AdvertTags.availability.generic.boat"

      case AdvertType.CREW:
        return "AdvertTags.availability.generic.crew"

      default:
        return null
    }
  }
}
