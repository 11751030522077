import { UserInfos } from "components/UserInfos"
import { Card, CardContent, CardMedia } from "components/Card"
import * as AdvertType from "enums/AdvertType"
import { Image } from "components/Image"
import { useAdvertInteractions } from "domains/advert/AdvertInteractionsProvider"
import cx from "classnames"
import { AdvertInteractions } from "domains/advert/AdvertInteractions"
import { AdvertRoute } from "domains/advert/AdvertRoute"
import { AdvertTags } from "domains/advert/AdvertTags"
import { AdvertDate } from "domains/advert/AdvertDate"
import { AdvertTitle } from "domains/advert/AdvertTitle"
import { AdvertDescription } from "domains/advert/AdvertDescription"
import { useRouter } from "components/Router"
import NoImagePlaceholder from "components/NoImagePlaceholder/NoImagePlaceholder.png"
import { RawLink } from "components/Link"
import { Text } from "components/Text"
import { useTranslator } from "components/Translator"
import { ValueWithUnit } from "components/ValueWithUnit"
import { Price } from "components/Price"
import { Anchor } from "components/Icon"
import { Tag } from "components/Tag"

export const AdvertResult = ({ advert, from }) => {
  const router = useRouter()
  const Component = advertTypeToComponent[advert.type.enum_value]
  const params = {
    userId: advert.user.id,
    advertId: advert.id,
  }

  if (from) {
    params.from = from
  }

  if (!Component) {
    console.warn(`Unknown advert type: ${advert.type.enum_value}`)
    return null
  }

  return (
    <Component
      advert={advert}
      href={router.generate("app_account_advert_show", params)}
    />
  )
}

const AdvertCrewResult = ({ advert, href }) => {
  return (
    <Card>
      <AdvertResultHeader advert={advert} />
      <CardContent>
        <div className="space-y-2">
          <RawLink href={href}>
            <AdvertTitle advert={advert} />
          </RawLink>
          <div className="bg-primary-lighter px-5 py-4">
            <UserInfos user={advert.user} variant="compact" />
          </div>
          <AdvertRoute advert={advert} variant="compact" />
          <AdvertTags advert={advert} />
          <AdvertDescription advert={advert} />
        </div>
      </CardContent>
    </Card>
  )
}

const AdvertBoatResult = ({ advert, href }) => {
  return (
    <Card>
      <AdvertResultHeader advert={advert} />
      <CardContent>
        <div className="space-y-2">
          <RawLink href={href}>
            <AdvertTitle advert={advert} />
          </RawLink>
          <AdvertRoute advert={advert} variant="compact" />
          <AdvertTags advert={advert} />
          <AdvertDescription advert={advert} />
        </div>
      </CardContent>
      <CardContent className="bg-primary-lighter">
        <UserInfos user={advert.user} variant="minimal" />
      </CardContent>
    </Card>
  )
}

const AdvertSaleResult = ({ advert, href }) => {
  const translator = useTranslator()

  return (
    <Card>
      <AdvertResultHeader advert={advert} />
      <CardContent>
        <div className="space-y-2">
          <RawLink href={href}>
            <AdvertTitle advert={advert} />
          </RawLink>
          {advert.boat ? (
            <>
              <Text className="text-light">
                {advert.boat.brandNormalized} {advert.boat.modelNormalized}
              </Text>
              {advert.boat.length?.value ? (
                <Text className="text-light">
                  {translator.trans(
                    "AdvertCard.boat.length",
                    null,
                    "components"
                  )}
                  {" : "}
                  <ValueWithUnit value={advert.boat.length} />
                </Text>
              ) : null}
            </>
          ) : null}
          <AdvertTags advert={advert} />
          <Text variant="headline5">
            { advert.salePrice ? (<Price
              amount={advert.salePrice.amount}
              currency={advert.salePrice.currency.enum_value}
            />) : null }
          </Text>
        </div>
      </CardContent>
      <CardContent>
        <AdvertDescription advert={advert} />
      </CardContent>
      <CardContent className="bg-primary-lighter">
        <UserInfos user={advert.user} variant="minimal" />
      </CardContent>
    </Card>
  )
}

const AdvertRentalResult = ({ advert, href }) => {
  const translator = useTranslator()

  return (
    <Card>
      <AdvertResultHeader advert={advert} />
      <CardContent>
        <div className="space-y-2">
          <RawLink href={href}>
            <AdvertTitle advert={advert} />
          </RawLink>
          <Text className="text-light">
            {advert.boat.brandNormalized} {advert.boat.modelNormalized}
          </Text>
          {advert.boat.length?.value ? (
            <Text className="text-light">
              {translator.trans("AdvertCard.boat.length", null, "components")}
              {" : "}
              <ValueWithUnit value={advert.boat.length} />
            </Text>
          ) : null}
          <AdvertTags advert={advert} />
          <AdvertDescription advert={advert} />
        </div>
      </CardContent>
      <CardContent className="bg-primary-lighter">
        <UserInfos user={advert.user} variant="minimal" />
      </CardContent>
    </Card>
  )
}

const AdvertFleetResult = ({ advert, href }) => {
  return (
    <Card>
      <AdvertResultHeader advert={advert} />
      <CardContent>
        <div className="space-y-2">
          <RawLink href={href}>
            <AdvertTitle advert={advert} />
          </RawLink>
          <AdvertRoute advert={advert} variant="compact" />
          <AdvertTags advert={advert} />
          <AdvertDescription advert={advert} />
        </div>
      </CardContent>
      <CardContent className="bg-primary-lighter">
        <UserInfos user={advert.user} variant="minimal" />
      </CardContent>
    </Card>
  )
}

const AdvertResultHeader = ({ advert }) => {
  const advertInteractions = useAdvertInteractions()
  const interactionsData = advertInteractions.get(advert.id)
  const visited = interactionsData?.lastContact ?? interactionsData?.lastVisit

  const imageFile = advert.imageFile || advert.boat?.imageFile
  const type = advert.type.enum_value

  const translator = useTranslator()

  return (
    <div className="space-y-2">
      {type !== AdvertType.CREW ? (
        <CardMedia
          media={
            imageFile ? (
              <Image
                imageFile={imageFile}
                size="w640"
                className={cx({ "filter grayscale": visited })}
              />
            ) : (
              <img src={NoImagePlaceholder} alt="" loading={"lazy"} />
            )
          }
          className="h-60"
        />
      ) : null}
      <div className="px-4 space-y-2">
        <div className="flex flex-row items-center justify-between">
          <Text variant="caption" className="text-light">
            #{advert.id}
          </Text>
          <AdvertDate advert={advert} />
          <AdvertInteractions advert={advert} variant="default" />
        </div>
        {[AdvertType.BOAT, AdvertType.FLEET].includes(advert.type.enum_value) &&
        advert.completed ? (
          <div>
            <Tag
              color="error"
              label={translator.trans("SearchResult.full", null, "components")}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

const advertTypeToComponent = {
  [AdvertType.CREW]: AdvertCrewResult,
  [AdvertType.BOAT]: AdvertBoatResult,
  [AdvertType.SALE]: AdvertSaleResult,
  [AdvertType.RENTAL]: AdvertRentalResult,
  [AdvertType.FLEET]: AdvertFleetResult,
}

export const BoatResult = ({ boat, from }) => {
  const router = useRouter()

  const params = {
    userId: boat.user.id,
    boatId: boat.id,
  }

  if (from) {
    params.from = from
  }

  return (
    <Card>
      <CardMedia
        media={
          boat.imageFile ? (
            <Image
              imageFile={boat.imageFile}
              size="w640"
              className="object-cover"
            />
          ) : (
            <img src={NoImagePlaceholder} alt="" loading={"lazy"} />
          )
        }
        className="h-48"
      />
      <CardContent>
        <div className="space-y-2">
          <RawLink href={router.generate("app_account_boat_show", params)}>
            <Text variant="headline3">{boat.name}</Text>
          </RawLink>
          <Text>{`${boat.type.enum_label} ${boat.brandNormalized} ${boat.modelNormalized}`}</Text>
          <Text>{boat.family.enum_label}</Text>
          {boat.address.search ? (
            <div className="flex space-x-1 items-baseline">
              <span className="shrink-0">
                <Anchor className="text-primary-default w-4" />
              </span>
              <Text>{boat.address.search}</Text>
            </div>
          ) : null}
        </div>
      </CardContent>
      <CardContent className="bg-primary-lighter">
        <UserInfos user={boat.user} variant="minimal" />
      </CardContent>
    </Card>
  )
}

export const UserResult = ({ user, from }) => {
  const router = useRouter()
  const params = {
    userId: user.id,
  }

  if (from) {
    params.from = from
  }

  return (
    <Card>
      <CardContent className="bg-primary-lighter">
        <UserInfos
          user={user}
          variant="search"
          href={router.generate("app_account_profile_show", params)}
        />
      </CardContent>
    </Card>
  )
}
