import { Text } from "components/Text"
import { useTranslator } from "components/Translator"

export const AdvertDate = ({ advert }) => {
  const translator = useTranslator()

  return (
    <Text variant="caption" className="text-light">
      {new Date(advert.updatedAt).toLocaleString(translator.locale)}
    </Text>
  )
}
