import { useTranslator } from "components/Translator"
import PropTypes from "prop-types"

export const ValueWithUnit = ({ value }) => {
  const translator = useTranslator()

  return (
    <span>
      {value.value.toLocaleString(translator.locale)} {value.unite.enum_label}
    </span>
  )
}

ValueWithUnit.propTypes = {
  value: PropTypes.shape({
    value: PropTypes.number.isRequired,
    unite: PropTypes.shape({
      enum_label: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}
