import { Route } from "components/Route"
import PropTypes from "prop-types"

export const AdvertRoute = ({ advert, variant }) => {
  const departure = {
    place: advert.departureNormalized,
    date: advert.departureDate ? new Date(advert.departureDate) : null,
  }

  const arrival = {
    place: advert.arrivalNormalized,
    date: advert.arrivalDate ? new Date(advert.arrivalDate) : null,
  }

  const stopovers =
    advert.stopovers?.map((stopover) => ({
      place: `${stopover.location.formatted ?? stopover.location.search}`,
      date: stopover.date ? new Date(stopover.date) : null,
    })) ?? []

  const steps = [
    departure.place ? departure : null,
    ...(variant === "full" ? stopovers : []),
    arrival.place ? arrival : null,
  ].filter(Boolean)

  if (steps.length === 0) {
    return null
  }

  return <Route steps={steps} />
}

AdvertRoute.propTypes = {
  variant: PropTypes.oneOf(["compact", "full"]).isRequired,
}
