import * as React from "react"
import { useAsync } from "@react-hook/async"
import { useRouter } from "components/Router"
import { useConnectedUser } from "components/ConnectedUserProvider"
import { client } from "api/client"

const AdvertInteractionsContext = React.createContext()

export const AdvertInteractionsProvider = ({ ids, children }) => {
  const router = useRouter()
  const [interactions, setInteractions] = React.useState(new Map())
  const connectedUser = useConnectedUser()

  const [fetchInteractionsRequest, fetchInteractions] = useAsync(
    async (ids) => {
      const url = new URL(router.generate("app_user_advert_infos", null, true))

      ids.forEach((id) => url.searchParams.append("ids[]", id))

      try {
        const response = await client.get(url)
        setInteractions(
          new Map(response.data.data.map((item) => [item.id, item]))
        )
      } catch (err) {
        if (err.response) {
          if (err.response.data.errors) {
            throw new Error(
              "Error while fetching advert interactions:",
              err.response.data.errors
            )
          }
        }

        throw err
      }
    }
  )

  const favorite = async (advertId) => {
    setInteractions((prevInteractions) => {
      const nextInteractions = new Map(prevInteractions)

      const item = nextInteractions.get(advertId) ?? { favorite: false }
      item.favorite = true

      nextInteractions.set(advertId, item)

      return nextInteractions
    })

    const url = router.generate("app_user_advert_favorite_add", {
      advertId,
    })

    try {
      await client.post(url)
    } catch (err) {
      if (err.response && err.response.data.errors) {
        console.error("Error while adding favorite:", err.response.data.errors)
      }

      console.error("Error while adding favorite:", err)
    }
  }

  const unfavorite = async (advertId) => {
    setInteractions((prevInteractions) => {
      const nextInteractions = new Map(prevInteractions)

      const item = nextInteractions.get(advertId) ?? { favorite: false }
      item.favorite = false

      nextInteractions.set(advertId, item)

      return nextInteractions
    })

    const url = router.generate("app_user_advert_favorite_delete", {
      advertId,
    })

    try {
      await client.delete(url)
    } catch (err) {
      if (err.response && err.response.data.errors) {
        console.error("Error while deleting favorite", err.response.data.errors)
      }

      console.error("Error while deleting favorite", err)
    }
  }

  React.useEffect(() => {
    if (connectedUser) {
      fetchInteractions(ids)
    }
  }, [fetchInteractions, ids, connectedUser])

  const get = (id) => {
    return interactions.get(id)
  }

  const toggleFavorite = (id) => {
    const item = interactions.get(id) ?? { favorite: false }

    return item.favorite ? unfavorite(id) : favorite(id)
  }

  const value = {
    get,
    favorite,
    unfavorite,
    toggleFavorite,
    status: fetchInteractionsRequest.status,
  }

  return (
    <AdvertInteractionsContext.Provider value={value}>
      {children}
    </AdvertInteractionsContext.Provider>
  )
}

export const useAdvertInteractions = () => {
  const context = React.useContext(AdvertInteractionsContext)

  if (!context) {
    throw new Error(
      "useAdvertInteractions must be used in an AdvertInteractionsProvider"
    )
  }

  return context
}
